import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { AUTHED_CUSTOMER_GQL } from '../../../../graphql';

export interface AuthedCustomerEffectArgs {
  onComplete?: (customer: Customer) => void;
  onError?: (error: string) => void;
}

export interface AuthedCustomerEffectReturn {
  enact: () => void;
  clear: () => void;
  data: Customer | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useAuthedCustomerEffect = (
  args?: AuthedCustomerEffectArgs
): AuthedCustomerEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Customer | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doAuthedCustomer, { loading }] = useLazyQuery(AUTHED_CUSTOMER_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: (data: { authedCustomer: Customer }) => {
      setData(data.authedCustomer);
      setSuccess(true);

      if (args?.onComplete) {
        args.onComplete(data.authedCustomer);
      }
    },
    onError: (errors) => {
      setSuccess(false);
      setErrorMessage(errors.message);

      if (args?.onError) {
        args.onError(errors.message);
      }
    },
  });

  const authedCustomer = (): void => {
    doAuthedCustomer();
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: authedCustomer,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
