import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginPageProps } from '../../server';
import {
  HeaderBasket,
  RegionRouteItem,
  usePageEnvironmentEffect,
  usePageFooterEffect,
  usePageHeaderEffect,
  useRoutingEffect,
  useUpsellEffect,
} from '../common';
import {
  useCustomerAuthLoginEffect,
  useCustomerLoginFormSetEffect,
  StoreState,
  FormInputChangeEvent,
  customerDataSlice,
} from '../../state';
import {
  AuthenticationLoginProps,
  AuthenticationProps,
  ButtonInputProps,
  TextInputProps,
  TextInputValidator,
} from '../../components';
import {
  useBasketTrialEffect,
  useBasketProductVariantEffect,
  useBasketTotalEffect,
  useBasketSubscriptionEffect,
  useBasketDisplayEffect,
} from '../basket';

export const useLoginPageEffect = (
  props: LoginPageProps
): AuthenticationProps => {
  const dispatch = useDispatch();

  const { display, region } = usePageEnvironmentEffect();

  const { basketDataState, customerDataState } = useSelector(
    (state: StoreState) => ({
      basketDataState: state.data.basket,
      customerDataState: state.data.customer,
    })
  );

  const { customer, invalidRegion } = customerDataState;

  const basketTrial = useBasketTrialEffect();
  const basketProductVariant = useBasketProductVariantEffect();
  const basketTotals = useBasketTotalEffect();
  const basketSubscription = useBasketSubscriptionEffect();
  const basketDisplay = useBasketDisplayEffect();

  const upsell = useUpsellEffect(
    basketDataState,
    basketProductVariant,
    region,
    false
  );

  const basket: HeaderBasket = {
    basketTrial,
    basketProductVariant,
    basketTotals,
    basketSubscription,
    basketDisplay,
    upsell: upsell?.basket,
    cartId: basketDataState.cart?.id || undefined,
    cartItems: basketDataState.countItems,
  };

  const form = useCustomerLoginFormSetEffect();

  const customerAuthLogin = useCustomerAuthLoginEffect();
  const { loading, error, success } = customerAuthLogin;

  const routing = useRoutingEffect(
    props.activeFeatures ? props.activeFeatures : undefined
  );

  const header = usePageHeaderEffect({
    template: 'headerDefault',
    styleClasses: ['headerBase'],
    routing,
    display,
    basket,
    activeFeatures: props.activeFeatures ? props.activeFeatures : undefined,
  });

  const footer = usePageFooterEffect('footerDefault', routing, display, region);

  const [hasResetPassword, setResetPassword] = useState<boolean>(false);
  const [isRedirecting, setRedirecting] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [regionLink, setRegionLink] = useState<RegionRouteItem | undefined>(
    undefined
  );

  const onSubmitHandler = () => {
    form.setSubmitted();

    customerAuthLogin.enact({
      email: form.members.email.value as string,
      password: form.members.password.value as string,
    });
  };

  const emailInputProps: TextInputProps = {
    type: 'email',
    styleClasses: ['textInputBase', 'textInputAuth'],
    placeholder: 'Email*',
    validators: form.members.email.validators as TextInputValidator[],
    valid: form.members.email.isValid,
    errors: form.members.email.errors?.length
      ? [form.members.email.errors[0]]
      : [],
    rules: form.members.email.rules,
    formSubmitted: form.isSubmitted,
    id: form.members.email.id,
    name: form.members.email.id,
    value: form.members.email.value as string,
    isTouched: form.members.email.isTouched || false,
    isDisabled: loading || isRedirecting || routing.isRedirecting,
    onChangeHandler: (event: FormInputChangeEvent) => {
      if (serverError) {
        setServerError(undefined);
      }

      if (regionLink) {
        setRegionLink(undefined);
      }

      form.members.email.updateValue(event);
    },
    onFocusHandler: () => form.members.email.setTouched(true),
    onBlurHandler: () => form.members.email.setBlurred(true),
    updateErrors: form.members.email.updateErrors,
  };

  const passwordInputProps: TextInputProps = {
    type: 'password',
    styleClasses: ['textInputBase', 'textInputAuth'],
    placeholder: 'Password*',
    validators: form.members.password.validators as TextInputValidator[],
    valid: form.members.password.isValid,
    errors: form.members.password.errors?.length
      ? [form.members.password.errors[0]]
      : [],
    rules: form.members.password.rules,
    formSubmitted: form.isSubmitted,
    id: form.members.password.id,
    name: form.members.password.id,
    value: form.members.password.value as string,
    isTouched: form.members.password.isTouched || false,
    isDisabled: loading || isRedirecting || routing.isRedirecting,
    onChangeHandler: (event: FormInputChangeEvent) => {
      if (form.members.email.errors.length > 0) {
        const errorIndex = form.members.email.errors.indexOf('Login failed!');

        if (errorIndex > -1) {
          const emailErrors = form.members.email.errors.filter(
            (errorMsg) => errorMsg !== 'Login failed!'
          );

          const valid = emailErrors.length === 0;
          form.members.email.updateErrors(valid, emailErrors);
        }
      }

      if (serverError) {
        setServerError(undefined);
        setResetPassword(false);
      }

      if (regionLink) {
        setRegionLink(undefined);
      }

      form.members.password.updateValue(event);
    },
    onFocusHandler: () => form.members.password.setTouched(true),
    onBlurHandler: () => form.members.password.setBlurred(true),
    updateErrors: form.members.password.updateErrors,
  };

  const submitInputProps: ButtonInputProps = {
    id: 'authentication-login-submit',
    name: 'authentication-login-submit',
    type: 'submit',
    template: 'buttonInputDefault',
    label: loading || isRedirecting ? 'PROCESSING...' : 'LOG IN',
    isDisabled: !!(
      !form.isValid ||
      loading ||
      isRedirecting ||
      routing.isRedirecting ||
      serverError
    ),
    isLoading: loading,
    styleClasses: ['buttonInputAuth'],
    onClickHandler: onSubmitHandler,
  };

  const loginSegmentProps: AuthenticationLoginProps = {
    id: 'authentication-login',
    template: 'authenticationLoginDefault',
    styleClasses: ['authenticationLoginBase'],
    welcomeMessage: 'Log in',
    form: {
      emailInputProps,
      passwordInputProps,
      submitInputProps,
    },
    hasResetPassword,
    serverError,
    regionLink,
    onForgotPasswordHandler: () => {
      setServerError(undefined);
      form.resetInputValues(true);
      routing.resetLink.onLinkHandler();
    },
    isLoading: loading,
  };

  useEffect(() => {
    if (customer && !isRedirecting && !routing.isRedirecting) {
      setRedirecting(true);
      routing.accountLink.onLinkHandler();
    }

    if (!loading) {
      if (success) {
        customerAuthLogin.clear();
        form.resetInputValues(true);
      }

      if (error && !serverError) {
        customerAuthLogin.clear();
        setServerError('Invalid email or password');
      }
    }

    if (invalidRegion && !regionLink) {
      setServerError(
        `This account is registered with our ${invalidRegion} store.`
      );

      switch (invalidRegion) {
        case 'UK':
          setRegionLink(routing.regionLinks.uk);
          break;
        case 'EU':
          setRegionLink(routing.regionLinks.eu);
          break;
        case 'SE':
          setRegionLink(routing.regionLinks.se);
          break;
        default:
        case 'US':
          setRegionLink(routing.regionLinks.us);
      }

      dispatch(
        customerDataSlice.actions.update({
          ...customerDataState,
          invalidRegion: undefined,
        })
      );
    }

    if (customerDataState.hasResetPassword && !hasResetPassword) {
      setResetPassword(true);

      dispatch(
        customerDataSlice.actions.update({
          ...customerDataState,
          hasResetPassword: false,
        })
      );
    }
  }, [
    isRedirecting,
    loading,
    success,
    error,
    invalidRegion,
    customerDataState.hasResetPassword,
  ]);

  return {
    id: 'authentication',
    template: 'authenticationDefault',
    styleClasses: ['authenticationBase'],
    display,
    header,
    footer,
    bannerActive: region !== 'US',
    loginSegmentProps,
  };
};
