import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { appStorage, AppStorageKey } from '../../../../../helpers';
import { LOGIN_CUSTOMER_GQL } from '../../../../graphql';

export interface LoginCustomerInput {
  email: string;
  password: string;
}

export interface LoginCustomerEffectArgs {
  onComplete?: () => void;
  onError?: (error: string) => void;
}

export interface LoginCustomerEffectReturn {
  enact: (data: LoginCustomerInput) => void;
  clear: () => void;
  data: boolean | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useLoginCustomerEffect = (
  args?: LoginCustomerEffectArgs
): LoginCustomerEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doLoginCustomer, { loading }] = useLazyQuery(LOGIN_CUSTOMER_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: (data: { loginCustomer: string }) => {
      appStorage.set(AppStorageKey.AUTH_TOKEN, data.loginCustomer);
      setData(true);
      setSuccess(true);

      if (args?.onComplete) {
        args.onComplete();
      }
    },
    onError: (errors) => {
      setSuccess(false);
      setErrorMessage(errors.message);

      if (args?.onError) {
        args.onError(errors.message);
      }
    },
  });

  const loginCustomer = (data: LoginCustomerInput): void => {
    doLoginCustomer({ variables: { ...data } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: loginCustomer,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
