import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  customerLoginFormSlice,
  CustomerLoginFormState,
  CustomerLoginFormValues,
  CustomerLoginFormSet,
  useFormInputEffect,
  requiredValidator,
  FormInput,
  StoreState,
  cleanCustomerLoginFormState,
} from '../../..';

export const useCustomerLoginFormSetEffect = (): CustomerLoginFormSet => {
  const dispatch = useDispatch();

  const customerLoginFormState = useSelector(
    (state: StoreState) => state.form.customer.login
  );

  const email = useFormInputEffect({
    id: 'customer-email-input',
    name: 'email',
    type: 'email',
    rules: {
      required: true,
    },
    validators: [requiredValidator],
    initialValue: customerLoginFormState.members.email.value,
    initialValid: customerLoginFormState.members.email.isValid,
    initialTouched: customerLoginFormState.members.email.isTouched,
    initialBlurred: customerLoginFormState.members.email.isBlurred,
    initialErrors: customerLoginFormState.members.email.errors,
  });

  const password = useFormInputEffect({
    id: 'customer-password-input',
    name: 'password',
    type: 'text',
    rules: {
      required: true,
    },
    validators: [requiredValidator],
    initialValue: customerLoginFormState.members.password.value,
    initialValid: customerLoginFormState.members.password.isValid,
    initialTouched: customerLoginFormState.members.password.isTouched,
    initialBlurred: customerLoginFormState.members.password.isBlurred,
    initialErrors: customerLoginFormState.members.password.errors,
  });

  const members = {
    email,
    password,
  };

  const setSubmitted = () => {
    const updatedCustomerLoginFormState: CustomerLoginFormState = Object.assign(
      {},
      {
        ...customerLoginFormState,
        isSubmitted: true,
      } as CustomerLoginFormState
    );

    dispatch(
      customerLoginFormSlice.actions.update(updatedCustomerLoginFormState)
    );
  };

  const getInputValues = (): CustomerLoginFormValues => {
    return {
      email: (members.email.value as string) || null,
      password: (members.password.value as string) || null,
    };
  };

  const resetInputValues = (wipe = false) => {
    members.email.doReset(wipe);
    members.password.doReset(wipe);

    dispatch(
      customerLoginFormSlice.actions.update(cleanCustomerLoginFormState)
    );
  };

  useEffect(() => {
    let valid = true;
    let touched = false;

    const formInputs = members as any as { [key: string]: FormInput };

    for (const i in formInputs) {
      if (!formInputs[i].isValid) {
        valid = false;
      }

      if (formInputs[i].isTouched) {
        touched = true;
      }
    }

    const updatedState: CustomerLoginFormState = {
      isValid: valid,
      isTouched: touched,
      isLoading: customerLoginFormState.isLoading,
      isSubmitted: customerLoginFormState.isSubmitted,
      members: {
        email: {
          isValid: members.email.isValid,
          isTouched: members.email.isTouched,
          isBlurred: members.email.isBlurred,
          value: members.email.value,
          errors: members.email.errors,
        },
        password: {
          isValid: members.password.isValid,
          isTouched: members.password.isTouched,
          isBlurred: members.password.isBlurred,
          value: members.password.value,
          errors: members.password.errors,
        },
      },
    };

    if (!_.isEqual(customerLoginFormState, updatedState)) {
      dispatch(customerLoginFormSlice.actions.update(updatedState));
    }
  }, [members]);

  return {
    isValid: customerLoginFormState.isValid,
    isTouched: customerLoginFormState.isTouched,
    isSubmitted: customerLoginFormState.isSubmitted,
    setSubmitted,
    getInputValues,
    resetInputValues,
    members,
  };
};
