import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Cart, Customer } from '@hiwaldo/sdk/interfaces';
import { Route } from '../../../../enums';
import {
  cartParser,
  legacyVersionBridge,
  StoreState,
  useGoogleTagManagerEffect,
} from '../../../';
import {
  LoginCustomerInput,
  useAssignCartCustomerEffect,
  useAuthedCustomerEffect,
  useFindBrowsingCartByCustomerIdEffect,
  useLoginCustomerEffect,
} from '../../../../server';
import {
  basketDataSlice,
  cartSlice,
  cleanBasketDataState,
  customerDataSlice,
  CustomerDataState,
} from '../../../redux';
import { currentRegion } from '../../../../helpers';

export interface CustomerAuthLoginDataEffectReturn {
  enact: (data: LoginCustomerInput) => void;
  clear: () => void;
  loading: boolean;
  success: boolean | undefined;
  error?: string;
}

export const useCustomerAuthLoginEffect = (
  checkout = false
): CustomerAuthLoginDataEffectReturn => {
  const dispatch = useDispatch();
  const router = useRouter();

  const appRegion = currentRegion();

  const { gtmCheckoutLogin } = useGoogleTagManagerEffect();

  const { basketDataState, cartState, customerDataState } = useSelector(
    (state: StoreState) => {
      return {
        basketDataState: state.data.basket,
        cartState: state.orders.cart,
        customerDataState: state.data.customer,
      };
    }
  );

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const findBrowsingCartByCustomerId = useFindBrowsingCartByCustomerIdEffect({
    onComplete: (cart: Cart | null) => {
      findBrowsingCartByCustomerId.clear();
      authedCustomer.clear();

      if (cart) {
        cartParser.fromCartUpdate(basketDataState, cart).then((updatedCart) => {
          dispatch(basketDataSlice.actions.update(updatedCart));
          setSuccess(true);
          setLoading(false);
        });
      } else {
        setSuccess(true);
        setLoading(false);
      }
    },
    onError: (error: string) => {
      dispatch(basketDataSlice.actions.update(cleanBasketDataState));
      dispatch(cartSlice.actions.clearCart());

      findBrowsingCartByCustomerId.clear();
      authedCustomer.clear();
      console.error('Failed to find browsing cart by customer id', error);
      setSuccess(true);
      setLoading(false);
    },
  });

  const assignCartCustomer = useAssignCartCustomerEffect({
    onComplete: (cart: Cart) => {
      assignCartCustomer.clear();
      authedCustomer.clear();

      cartParser.fromCartUpdate(basketDataState, cart).then((updatedCart) => {
        dispatch(basketDataSlice.actions.update(updatedCart));
        setSuccess(true);
        setLoading(false);
      });
    },
    onError: (error: string) => {
      dispatch(basketDataSlice.actions.update(cleanBasketDataState));
      dispatch(cartSlice.actions.clearCart());

      assignCartCustomer.clear();
      authedCustomer.clear();
      console.error('Failed to assign cart to customer', error);

      if (error.toLowerCase().match(/duplicate/)) {
        router.push(Route.FUNNEL_ACTIVE_SUBSCRIPTION);
      } else {
        setSuccess(false);
        setLoading(false);
      }
    },
  });

  const authedCustomer = useAuthedCustomerEffect({
    onComplete: (customer: Customer) => {
      if (customer.region !== appRegion) {
        const updatedState: CustomerDataState = Object.assign({}, {
          ...customerDataState,
          invalidRegion: customer.region,
          hasResetPassword: false,
        } as CustomerDataState);

        dispatch(customerDataSlice.actions.update(updatedState));
        setSuccess(false);
        setLoading(false);
      } else {
        const updatedState: CustomerDataState = Object.assign({}, {
          ...customerDataState,
          customer,
          loggedIn: true,
          allowSSE: true,
          invalidRegion: undefined,
          hasResetPassword: false,
        } as CustomerDataState);

        dispatch(customerDataSlice.actions.update(updatedState));
        legacyVersionBridge.customer.authed(dispatch, customer);
        authedCustomer.clear();

        const cart = basketDataState.cart || cartState.cart || undefined;

        if (checkout) {
          gtmCheckoutLogin(customer.id);
        }

        if (!cart) {
          findBrowsingCartByCustomerId.enact({
            customerId: customer.id,
          });
        } else {
          assignCartCustomer.enact({
            customerId: customer.id,
            cartId: cart.id,
          });
        }
      }
    },
    onError: (error: string) => {
      authedCustomer.clear();

      const updatedState: CustomerDataState = Object.assign({}, {
        ...customerDataState,
        hasResetPassword: false,
      } as CustomerDataState);

      dispatch(customerDataSlice.actions.update(updatedState));

      console.error('Failed to fetch authed user', error);
      setErrorMessage(error);
      setSuccess(false);
      setLoading(false);
    },
  });

  const loginCustomerUser = useLoginCustomerEffect({
    onComplete: () => {
      loginCustomerUser.clear();
      authedCustomer.enact();
    },
    onError: (error: string) => {
      loginCustomerUser.clear();

      const updatedState: CustomerDataState = Object.assign({}, {
        ...customerDataState,
        hasResetPassword: false,
      } as CustomerDataState);

      dispatch(customerDataSlice.actions.update(updatedState));

      setErrorMessage(error);
      setSuccess(false);
      setLoading(false);
    },
  });

  const customerAuthLogin = (data: LoginCustomerInput) => {
    setLoading(true);
    loginCustomerUser.enact(data);
  };

  const clear = () => {
    setErrorMessage(undefined);
    setSuccess(undefined);
    setLoading(false);
  };

  return {
    enact: customerAuthLogin,
    clear,
    loading,
    success,
    error: errorMessage,
  };
};
