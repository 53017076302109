import Head from 'next/head';
import React from 'react';
import { AuthenticationLayout } from '../components';
import { useLoginPageEffect } from '../hooks';
import { staticPageLogin, LoginPageProps } from '../server';

export const getStaticProps = staticPageLogin;

const Login = (props: LoginPageProps) => {
  const defaultProps = useLoginPageEffect(props);

  return (
    <>
      <Head>
        <title>Waldo Hydra Boost Plus | WALDO Daily Contacts</title>
      </Head>
      <AuthenticationLayout {...defaultProps} />
    </>
  );
};

export default Login;
